<template>
  <KTCodePreview v-bind:title="'In phiếu'">
    <template v-slot:toolbar>
      <b-button
        variant="primary"
        size="sm"
        @click="printData"
        style="width: max-content"
      >
        <i class="fas fa-print"></i>
        In phiếu
      </b-button>
    </template>
    <template v-slot:preview>
      <div id="printMe">
        <div class="print-content">
          <div class="heading">
            <div class="heading-left">
              <h2 class="font-size-large">CÔNG TY TNHH CÔNG NGHỆ DI ĐỘNG VIỆT</h2>
              <p>Địa chỉ: 75/35 Nguyễn Cửu Vân, Phường 17, Quận Bình Thạnh, HCM, Việt Nam</p>
              <p>Mã số thuế: 0312193244</p>
            </div>
            <div class="heading-right">
              <p style="margin-bottom: 0;">Mẫu số: <span style="font-weight:500">01/TNDN</span></p>
              <p style="margin-bottom: 0;">(Ban hành kèm theo Thông tư số 78/2014/TT-BTC của Bộ Tài chính)</p>
            </div>
          </div>

          <div class="hero">
            <h2 class="font-size-large">BẢNG KÊ THU MUA HÀNG HOÁ, DỊCH VỤ MUA VÀO KHÔNG CÓ HOÁ ĐƠN</h2>
            <p>Ngày {{ printDay }}</p>
          </div>

          <div class="body-content">
            <p>
              <span class="custom-lable-text text-bold">Họ tên khách hàng (người bán)</span>
              : <span class="text-center">{{ customerInfo.providerName }}</span>
            </p>
            <p>
              <span class="custom-lable-text text-bold">Số điện thoại</span>
              : {{ customerInfo.customerPhone }}
            </p>
            <p>
              <span class="custom-lable-text text-bold">CMND/CCCD</span>
              : {{ customerInfo.customerIdentityNo }}
            </p>
            <p>
              <span class="custom-lable-text text-bold">Địa chỉ liên hệ</span>
              : {{ customerInfo.address }}
            </p>
            <p>
              <span class="custom-lable-text text-bold">
                Hình thức thanh toán:
              </span>
              <span v-if="(customerInfo.cashAmount > 0)">Tiền mặt</span>
              <span v-if="(customerInfo.transferAmount > 0)">, chuyển khoản</span>
            </p>
            <p>
              <span class="custom-lable-text text-bold">
                Người phụ trách thu mua
              </span>
              : {{ customerInfo.storeName }}
            </p>
            <p>
              <span class="custom-lable-text text-bold">
                Địa chỉ nơi tổ chức thu mua
              </span>
              : {{ customerInfo.storeAddress }}
            </p>
            <div class="list-product">
              <table>
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      style="width: 5%"
                    >
                      <span>STT</span>
                    </th>
                    <th>
                      <span>Tên sản phẩm</span>
                    </th>
                    <th>
                      <span>IMEI</span>
                    </th>
                    <th><span>SL</span></th>
                    <th class="text-right"><span>Đơn giá</span></th>
                    <th class="text-right"><span>Coupon trợ giá</span></th>
                    <th class="text-right">
                      <span>Thành tiền</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(product, index) in customerInfo.listDetail">
                    <tr :key="index">
                      <td class="text-center">
                        <span>{{ product.count }}</span>
                      </td>
                      <td style="width: 40%">
                        <span v-if="product.orderItemType === 2"><i
                            data-v-f69b43ce=""
                            class="fas fa-gift ml-4"
                            style="color: rgb(24, 28, 50);"
                          ></i> {{ product.productName }}</span>
                        <span v-else>{{ product.productName }}</span>
                      </td>
                      <td class="text-center">
                        <span>{{ product.productImei }}</span>
                      </td>
                      <td class="text-center">
                        <span>{{ product.quantity }}</span>
                      </td>
                      <td class="text-right">
                        <span>
                          {{ convertPrice(product.totalAmount - couponTotal) }}
                        </span>
                      </td>
                      <td class="text-right">
                        <span v-if="couponTotal > 0"> {{ convertPrice(couponTotal) }}</span>
                        <span v-else></span>
                      </td>
                      <td class="text-right">
                        <span>{{ convertPrice(product.totalAmount) }}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>

                <tfoot>
                  <tr>
                    <td colspan="6">
                      <span class="text-bold text-footer">Tổng tiền:</span>
                    </td>
                    <td colspan="2">
                      <span class="text-bold font-size-print">{{ formatMoney(calculateTotal('totalAmount')) }}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="total-amount">
              <p class=" text-bold">
                <span class="custom-lable-text">Tổng số tiền (viết bằng chữ)</span>
                : {{ convertNumberToText(calculateTotal('totalAmount')) }}
              </p>
            </div>
            <!-- Kí kết -->
            <div class="sign-in">
              <div class="signing-left">
                <div class="sign-customer">
                  <span class="font-size-print">Người bán</span>
                </div>
                <div class="sign-sale">
                  <span class="font-size-print">Bộ phận kho</span>
                </div>
              </div>
              <div class="signing-right">
                <div class="sign-accountant">
                  <span class="font-size-print">Kế toán cửa hàng</span>
                </div>
                <div class="sign-manager">
                  <span class="font-size-print">Quản lý cửa hàng</span>
                </div>
              </div>
            </div>

            <div
              class="attach-img"
              v-if="attachments && attachments.length"
            >
              <img
                :src="attachments[0].url"
                alt="attachments[0].name"
              >
              <img
                v-if="attachments[1]"
                :src="attachments[1].url"
                alt="attachments[1].name"
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </KTCodePreview>
</template>

<style scoped>
@media print {
  .print-content {
    padding: 40px;
  }
}

body .print-content {
  --text-bold: 500;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: black;
  font-size: 18px;
  padding: 20px;
}

p {
  margin-bottom: 0.5rem;
}

.font-size-print {
  font-size: 18px;
}

.font-size-large {
  font-size: 22px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heading-left {
  font-weight: var(--text-bold);
  flex: 2;
}

.heading-right,
.heading-left p:last-child {
  margin-bottom: 0;
}

.heading-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid black;
}

.hero {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: var(--text-bold);
}

.hero h2 {
  width: 450px;
}

.body-content {
  margin-top: 60px;
}

.list-product {
  margin-top: 20px;
}

.text-bold {
  font-weight: var(--text-bold);
}

table {
  width: 100%;
  font-size: 18px;
}

table,
th,
td {
  border: 1px solid black;
  padding: 8px;
}

table tfoot td {
  text-align: right;
}

table tfoot td:first-child span {
  display: inline-block;
  margin-right: 12px;
}

table th {
  text-align: center;
}

.text-center {
  text-align: center;
}

.total-amount {
  margin-top: 20px;
}

.sign-in {
  display: flex;
  height: 100px;
  margin-top: 80px;
  justify-content: space-around;
  font-weight: var(--text-bold);
}

.signing-left {
  display: flex;
  gap: 100px;
}
.signing-right {
  display: flex;
  position: relative;
  gap: 100px;
}

.signing-right::before {
  content: 'Cửa hàng';
  width: 100px;
  height: 10px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: var(--text-bold);
}

.sign-customer::after,
.sign-sale::after,
.sign-accountant::after,
.sign-manager::after {
  content: '(Ký, họ tên)';
  display: inherit;
  font-style: italic;
  font-size: 16px;
  text-align: center;
}

.attach-img {
  margin-top: 140px;
  display: flex;
  justify-content: center;
  gap: 120px;
}

.attach-img img {
  width: 360px;
  height: 200px;
  object-fit: cover;
}

.text-footer {
  font-weight: bold;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  convertNumberToText,
  makeToastFaile,
  convertPrice,
  isFeatureActive
} from '@/utils/common';
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { TIME_TRIGGER } from '@/utils/constants';
import _ from 'lodash';
import axios from 'axios';
import { cmdUrl } from '@/utils/apiUrl';
import JwtService from '@/core/services/jwt.service';
import { TRADEIN_PROGRAM_ADJUST_OPERATOR_TYPE } from '../../../utils/enum';
import { checkPermission } from '../../../utils/saveDataToLocal';

const PRICE_SLIP = 1; // phiếu thu cũ
const BUYING_OLD_ITEMS_SLIP = 2; // phiếu định giá
export default {
  data() {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      customerInfo: {
        providerName: '',
        providerId: '',
        customerPhone: '',
        address: '',
        customerIdentityNo: '',
        description: '',
        storeName: '',
        storeAddress: '',
        relatedStockCode: '',
        createdAt: '',
        cashAmount: 0,
        transferAmount: 0,
        listDetail: [],
      },
      attachments: [],
      couponTotal: 0,
      tradeInAddAdjustamount: false,
    };
  },
  components: {
    KTCodePreview,
  },
  async created() {
    this.tradeInAddAdjustamount = await isFeatureActive('trade-in-add-adjustamount');
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: '/import-trade-in' },
      { title: 'In phiếu thu cũ' },
    ]);
    if (this.type === BUYING_OLD_ITEMS_SLIP) {
      this.fetchImportTradeInById();
    }
    if (this.type === PRICE_SLIP) {
      this.fetchTradeIn();
    }
  },
  computed: {
    getCurrentDate() {
      return moment().lang('vi').format('LL');
    },
    calculateTotal() {
      return (attribute) => {
        let total = 0;
        _.map(this.customerInfo.listDetail, (item) => {
          total += item[attribute];
        });
        return total;
      };
    },
    isEnableShowAddAjustAmount(){
      return this.tradeInAddAdjustamount;
    },
    printDay() {
            if (this.type === BUYING_OLD_ITEMS_SLIP) {
                return checkPermission('TRADE_IN_INFO_PRINT')
                    ? moment().lang('vi').format('LL')
                    : this.customerInfo.createdAt;
            } else {
                return moment().lang('vi').format('LL');
            }
    }
  },
  methods: {
    convertPrice,
    convertNumberToText,
    printData() {
      const prtHtml = document.getElementById('printMe').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1280,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}

          <style>
            @media print {
              body {  
                font-size: 21px !important;
              };
            }
          </style>
        </head>
        <body`);
      WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, TIME_TRIGGER);
    },
    formatMoney(number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    loadAttachments(id, entity) {
      if (!id) {
        return;
      }
      ApiService.query('file', {
        params: {
          entity: entity,
          entityId: id,
        },
      }).then(async (resp) => {
        if (resp.status === 200) {
          const attachments = resp.data.data.map((item) => ({
            id: item.id,
            fileName: item.fileName,
            originalName: item.fileName,
          }));

          this.attachments = await Promise.all(
            attachments.map(async ({ fileName, ...rest }) => {
              const resp = await axios.get(
                `${cmdUrl.File.download}?filename=${fileName}`,
                {
                  responseType: 'arraybuffer',
                  headers: {
                    Authorization: `Bearer ${JwtService.getToken()}`,
                  },
                },
              );
              const contentType = resp.headers['content-type'];
              const base64 = `data:${contentType};base64,${Buffer.from(
                resp.data,
              ).toString('base64')}`;
              return {
                ...rest,
                url: base64,
                fileName,
              };
            }),
          );
        }
      });
    },
    fetchTradeIn() {
      ApiService.get(`tradeIn/${this.id}`).then(({ data }) => {
        this.loadAttachments(this.id, 'evaluation-trade');
        if (data.status === 1) {
          const responseData = data.data;
          this.customerInfo.relatedStockCode = responseData.id;
          this.customerInfo.providerName = responseData.customerName;
          this.customerInfo.customerPhone = responseData.customerPhone;
          this.customerInfo.address = responseData.customerAddress;
          this.customerInfo.customerIdentityNo =
            responseData.customerIdentifyNo;
          this.customerInfo.storeName = responseData.storeName;
          this.customerInfo.storeAddress = responseData.storeAddress;
          this.customerInfo.createdAt = moment(responseData.createdDate).format(
            'DD/MM/YYYY',
          );
          const item = {
            count: 1,
            orderItemType: 1,
            productName: responseData.productName,
            productImei: responseData.imeiCode,
            quantity: 1,
            unitPrice: responseData.finalBuyingPrice,
            totalAmount: responseData.finalBuyingPrice,
          };
          this.customerInfo.listDetail.push(item);
          let couponTotal = 0;
          responseData.listCreteriaGroup.forEach(group => {
            // Kiểm tra xem criterialName có chứa "coupon" không (không phân biệt hoa thường)
            if (group.criterialName.toLowerCase().includes("coupon")) {
              // Duyệt qua listItem trong nhóm
              group.listItem.forEach(item => {
                // Kiểm tra điều kiện selected != false
                if (item.selected) {
                  // Kiểm tra operatorType và tính toán couponTotal
                  couponTotal = this.calculateCouponTotal(couponTotal, item.operatorType, item.amount);

                  // Tính toán adjustAmount theo adjustOperatorType
                  if(this.isEnableShowAddAjustAmount){
                  couponTotal = this.calculateCouponTotal(couponTotal, item.adjustOperatorType, item.adjustAmount);
                  }
                }
              });
            }
          });
          this.couponTotal = couponTotal; 
        } else {
          makeToastFaile('Không lấy được thông tin');
        }
      });
    },
    fetchImportTradeInById() {
      ApiService.get(`stocks/${this.id}`).then(({ data }) => {
        this.loadAttachments(this.id, 'import-trade-in');
        if (data.status === 1) {
          const responseData = data.data;
          this.customerInfo.providerName = responseData.providerName;
          this.customerInfo.providerId = responseData.providerId;
          this.customerInfo.customerPhone = checkPermission('TRADE_IN_INFO_PRINT') ? responseData.customerPhone : this.maskPhoneNumber(responseData.customerPhone);
          this.customerInfo.address = responseData.address;
          this.customerInfo.customerIdentityNo =
            responseData.customerIdentityNo;
          this.customerInfo.description = responseData.description;
          this.customerInfo.storeName = responseData.storeName;
          this.customerInfo.storeAddress = responseData.storeAddress;
          this.customerInfo.relatedStockCode = responseData.relatedStockCode;
          this.customerInfo.createdAt = moment(responseData.createdAt).format(
            'DD/MM/YYYY',
          );
          this.customerInfo.cashAmount = responseData.cashAmount;
          this.customerInfo.transferAmount = responseData.transferAmount;
          responseData.listDetail.map((element, index) => {
            const item = {
              count: ++index,
              ...element,
            };
            this.customerInfo.listDetail.push(item);
          });
          // setTimeout(() => {
          //   this.printData();
          // }, TIME_TRIGGER);
        } else {
          makeToastFaile('Không lấy được thông tin');
        }
      });
      const transferStockSlipId = this.$route.query.transferStockSlipId;
      ApiService.get(`tradeIn/${transferStockSlipId}`).then(({ data }) => {
        this.loadAttachments(this.id, 'evaluation-trade');
        if (data.status === 1) {
          const responseData = data.data;
          let couponTotal = 0;
          responseData.listCreteriaGroup.forEach(group => {
            // Kiểm tra xem criterialName có chứa "coupon" không (không phân biệt hoa thường)
            if (group.criterialName.toLowerCase().includes("coupon")) {
              // Duyệt qua listItem trong nhóm
              group.listItem.forEach(item => {
                // Kiểm tra điều kiện selected != false
                if (item.selected) {
                  // Kiểm tra operatorType và tính toán couponTotal
                  couponTotal = this.calculateCouponTotal(couponTotal, item.operatorType, item.amount);

                  // Tính toán adjustAmount theo adjustOperatorType
                  if(this.isEnableShowAddAjustAmount){
                  couponTotal = this.calculateCouponTotal(couponTotal, item.adjustOperatorType, item.adjustAmount);
                  }
                }
              });
            }
          });
          this.couponTotal = couponTotal; 
        } else {
          makeToastFaile('Không lấy được thông tin');
        }
      });
    },
    calculateCouponTotal(total, operatorType, amount) {
      if (operatorType === TRADEIN_PROGRAM_ADJUST_OPERATOR_TYPE.ADDITION) {
        return total + amount;
      } else if (operatorType === TRADEIN_PROGRAM_ADJUST_OPERATOR_TYPE.SUBTRACTION) {
        return total - amount;
      }
      return total;
    },
    maskPhoneNumber(phoneNumber) {
      // Chuyển tất cả ký tự trừ 4 ký tự cuối thành '*'
      return phoneNumber.slice(0, -4).replace(/./g, '*') + phoneNumber.slice(-4);
    }
  },
  watch: {
    $route(to) {
      this.id = to.query.id;
    },
  },
};
</script>
